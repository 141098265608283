import React from "react"
import { graphql } from "gatsby"

// import Layout from "../../components/layouts/layout"
// import SEO from "../../components/seo"
// import Header4 from "../../components/contents/header4"
// import CdaContent from "../../content/cda/cda_content"

import { Link } from "gatsby"




const Page = ({ data, location }) => (
  /* console.log(data.site, data.site.siteMetadata.cda), */
//   <Layout
//     header={
//       <Header4
//         src_sfondo={data.file.childImageSharp.fluid}
//         title={data.site.siteMetadata.cda.title}
//         link_page={data.site.siteMetadata.cda.link_page}
//       />
//     }
//     location={location}
//   >
//     <SEO
//       title={data.site.siteMetadata.cda.title}
//       pathname={data.site.siteMetadata.cda.link_page}
//     />

//     <CdaContent></CdaContent>
//   </Layout>

    <div>
        Ricerca & Sviluppo
        <br />
        <Link to="/ricercaesviluppo/horizon">test</Link>
    </div>
)

export const query = graphql`
  {
    file(relativePath: { regex: "/cda/header/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        cda {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default Page
